<template>
  <el-dialog class="small-padding" :title="title" :visible.sync="dialogVisible" width="800" top="5vh">
    <div>
      <vm-table ref="vmTable" v-loading="loading" :filter.sync="filter" url="devices/onlineLogs">
        <table-column prop="code" :label="$t('device.code')" align="center"></table-column>
        <table-column :label="$t('device.status')" align="center">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.status === 1">{{ $t("workOrder.onLine") }}</el-tag>
            <el-tag v-else type="info">{{ $t("workOrder.offLine") }}</el-tag>
          </template>
        </table-column>
        <table-column prop="createTime" :label="$t('workOrder.createTime')" align="center"></table-column>
      </vm-table>
    </div>
  </el-dialog>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      title: this.$t("device.record"),
      dialogVisible: false,
      loading: false,
      filter: {
        code: "",
      },
    };
  },
  mounted() {
  },
  methods: {
    open(code) {
      this.dialogVisible = true;
      if (code) {
        this.filter.code = code;
        this.$nextTick(() => {
          this.getList(1);
        });
      }
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    changePage(pageIndex) {
      this.getList(pageIndex);
    },
  },
};
</script>
<style lang="scss" scoped></style>
